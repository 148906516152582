import React, { useState } from "react";

function Login({
  abilityUrl,
  loginUrl,
  redirectUrl,
  translations,
  token
}) {
  const email = React.createRef();

  const [errorMessages, setErrorMessages] = useState({ });
  const [continuable, setContinuable] = useState(true);

  const [SSOSignable, setSSOSignable] = useState(false);

  const handleClickContinue = (event) => {
    event.preventDefault();

    const value = email.current.value;

    if (isValidEmail(value)) {
      setContinuable(false);
      setErrorMessages({ });
    } else {
      setContinuable(true);
      setErrorMessages({
        name: "email",
        message: translations.errors.incorrect_email
      })
    }
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.[a-zA-Z]{2,}$/.test(email);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    if (isValidEmail(value))
      setSSOSignable(ableToLoginWithSSO(value));
    if (!value) {
      setContinuable(false);
      setSSOSignable(false);
    }
  };

  const ableToLoginWithSSO = (email) => {
    $('#main-content').addClass('loading');
    setErrorMessages({ });
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    }
    fetch(abilityUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        email: email
      })
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.sso) {
        setSSOSignable(true);
        setContinuable(false);
      }
      $('#main-content').removeClass('loading');
    })
    .catch(() => {
      setSSOSignable(false);
      $('#main-content').removeClass('loading');
    });
  }

  const handleRedirectToSSO = () => {
    window.location.href = redirectUrl;
  }

  const renderErrorMessage = (name) => {
    if (name === errorMessages.name)
      return (
        <div className="text-danger">{errorMessages.message}</div>
      )
  }
  return (
      <React.Fragment>
        <h2>{translations.title}</h2>
        <form className="simple_form form-horizontal" action={loginUrl} acceptCharset="UTF-8" method="post">
          <input name="authenticity_token" type="hidden" value={token} />
          <div className="control-group email required user_email">
            <label className="control-label email required" htmlFor="user_email">
              <abbr title="wymagane">*</abbr>
              {translations.email}
            </label>
            <div className="controls">
              <input
                ref={email}
                className="string email required"
                type="email"
                name="user[email]"
                id="user_email"
                onChange={handleEmailChange}
              />
            </div>
            <br></br>
            {renderErrorMessage("email")}
          </div>
          {SSOSignable ? (
            <input
              name="commit"
              value={translations.login_sso}
              onClick={handleRedirectToSSO}
              className="btn3 btn-block btn-login-sso"
              id="login"
              data-disable-with="Zaloguj się"
            />
          ) : (
            <React.Fragment>
              {continuable ? (
                <button
                  onClick={handleClickContinue}
                  className="btn3 btn-block btn-login-sso">
                    {translations.continue}
                </button>
              ) : (
              <React.Fragment>
                <div className="control-group password required user_password">
                  <label className="control-label password required" htmlFor="user_password">
                    <abbr title="wymagane">*</abbr>
                    {translations.password}
                  </label>
                  <div className="controls">
                    <input
                      className="password required"
                      type="password"
                      name="user[password]"
                      id="user_password"
                    />
                  </div>
                </div>
                <div className="controls">
                  <label className="checkbox">
                    <input name="user[remember_me]" type="hidden" value="0" autoComplete="off" />
                    <input type="checkbox" value="1" name="user[remember_me]" id="user_remember_me" />
                    {translations.remember_me}
                  </label>
                </div>
                <br></br>
                <input
                  type="submit"
                  name="commit"
                  value="Sign in"
                  className="btn3 btn-block"
                  data-disable-with="Processing..."
                />
              </React.Fragment>)}
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
  );
}

export default Login;
