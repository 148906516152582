/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

var $ = require('jquery')
var ImageEditor = require('tui-image-editor');

var instance;
if (typeof picture !== 'undefined') {
	instance = new ImageEditor(document.querySelector('#image-editor'), {
		includeUI: {
			loadImage: picture,
			locale: locale,
			theme: theme,
			initMenu: 'draw',
			menuBarPosition: 'left',
			menu: ['draw', 'text']
		},
	 cssMaxWidth: 800,
	 cssMaxHeight: 600,
	 selectionStyle: {
		 cornerSize: 20,
		 rotatingPointOffset: 70
	 },
 });
}

var button = '<button id="save-image">' + locale.Download + '</button>';
$('.tui-image-editor-header-buttons').append(button);

$(document).on('click','#save-image', function(){
	$('body').addClass('loading');
	$.ajax({
		url: '/verify/images',
		dataType: 'script',
		type: 'post',
		headers: {
    		'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  		},
		data: {
			image: {
				core_id: picture.core_id,
				image: instance.toDataURL(),
				image_id: picture.id
			}
		},
		success: function(){
			window.opener.refreshStateContent(picture.core_id);
			window.close();
		},
		error: function(){
			alert('Powstał błąd przy próbie zapisie zdjęcia. Spróbuj ponownie.')
			$('body').removeClass('loading');
		}
	});
});// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
